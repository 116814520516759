.navbar-container {
	position: sticky;
	z-index: 10;
	width: 100%;
	/* background-color: var(--primary-color); */
	justify-content: center;
	padding-bottom: 0.5rem;
	align-items: center;
}

.navbar {
	display: flex;
	position: relative;
	z-index: 100;
	height: 56px;
	padding: 7px 40px;
	margin: 0;
	width: 100%;
	justify-content: space-between;
	background-color: var(--secondary-color);
}

.navbar-container::before {
	content: "";
	position: absolute;
	bottom: 0;
	right: 0;
	transform: translateY(100%);
	height: 1rem;
	width: 100%;
	background-color: var(--primary-color);
	background: linear-gradient(
		to top,
		transparent 0%,
		var(--primary-color) 100%
	);
}

.username-item {
	padding: 10px 20px;	
	cursor: pointer;
	font-weight: 750;
	color: var(--text-color);
}

.nav-items-array {
	display: flex;
	align-items: space-between;
	justify-content: center;
}

.nav-item {
	padding: 10px 20px;
	color: var(--text-color);
	text-decoration: none;
	cursor: pointer;
}

.nav-brand {
	font-weight: 750;
}
