.submit-button-outer {
	padding: 0 8px;
	min-width: 4rem;
}

.submit-button {
	background-color: var(--accent-color);
	min-width: 5.5rem;
	color: white;
	border: none;
	border-radius: 32px;
	padding: .8rem 1.6rem;
	cursor: pointer;
	border: 1px solid transparent;
	transition: all 0.3s;
}

.submit-button:hover {
	opacity: 0.80;
	border: 1px solid var(--text-color);
	/* background-color: var(--secondary-color); */
	/* color: var(--accent-color);
	border: 1px solid var(--accent-color); */
}