.login-page {
	display: flex;
	position: relative;
	justify-content: space-evenly;
	align-items: center;
	/* height: 100%;
	width: 100vw; */
}

.home-door {
	position: absolute;
	top: 50%;
	left: -12rem;
}

.register-door {
	position: absolute;
	top: 50%;
	right: -12rem;
}

.home-icon {
	display: flex;
	cursor: pointer;
	justify-content: center;
	align-items: center;
	width: 48px;
	height: 48px;
	border-radius: 100px;
	padding: .5rem;
	background-color: white;
}

.register-icon {
	display: flex;
	cursor: pointer;
	justify-content: center;
	align-items: center;
	width: 48px;
	height: 48px;
	border-radius: 100px;
	padding: .5rem;
	background-color: white;
}

.login-container {
	display: flex;
	width: auto;
	position: relative;
	overflow: hidden;
	justify-content: space-evenly;
	align-items: center;
	padding: 32px 72px;
	background-color: var(--secondary-color);
	border: transparent 2px solid;
	border-radius: 46px;
}

.login-cover {
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 604px;
}

.login-header {
	display: flex;
	justify-content: center;
	font-size: 15px;
	align-items: center;
	padding: 15px 0;
}

.login-form {
	display: flex;
	gap: .8rem;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 1.5rem;
	margin: 1.8rem 2rem;
}

.login-input {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 212px;
	padding: 10px 20px;
	margin: 10px 0;
	border: 2px solid var(--primary-color);
	border-color: #acacac;
	border-radius: 64px;
	box-shadow: inset 4px 4px 4px 0 rgba(0, 0, 0, 0.15);
	transition: all 0.2s;
}

.login-input:hover {
	box-shadow: inset 4px 4px 4px 0 rgba(0, 0, 0, 0.10);
}

.login-input:focus {
	outline: none;
	box-shadow: inset 4px 4px 4px 0 rgba(0, 0, 0, 0.10);
	border-color: var(--accent-color);
}

.login-label {
	padding: 0 16px;
	font-weight: 600;
}


.join-form {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 32px;
	padding: .8rem 0rem;
}

.email-field {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: .8rem .8rem;
}

.email-input {
	width: 80%;
}

.input-field {
	width: 100%;
}

.corner-button {
	position: absolute;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(2, 1fr);
	bottom: 0;
	right: 0;
	padding: 4px 24px;
	transform: translate(40%, 50%);
	background-color: var(--primary-color);
	/* border: 1px solid var(--primary-color); */
	/* border-color: var(--primary-color); */
	box-shadow: inset 8px 0px 8px 0 rgba(0, 0, 0, 0.2);
	border-radius: 50px;
	transition: all 0.3s;
}

.login-button {
	text-align: center;
	background: transparent;
	font-size: 14px;
	border: none;
	padding: 16px 24px;
	color: white;
	font-weight: 700;
}

.login-button:hover {
	cursor: pointer;
}

.corner-button:hover {
	cursor: pointer;
	box-shadow: inset 4px 0px 8px 0 rgba(0, 0, 0, 0.15);
}

.login-sep {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px 16px;
	margin: 10px 16px;
}

.oauth-container {
	/* display: flex; */
	justify-content: center;
	align-items: center;
	padding: 10px 10px;
	/* margin: 10px 10px; */
	/* border: 1px solid var(--primary-color);
	border-color: var(--primary-color);
	border-radius: 10px;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1); */
}

.oauth-google {
	display: flex;
	justify-content: space-around;
	align-items: center;
	/* padding: 10px 20px;
	margin: 10px 0; */
}

.google-button {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px 20px;
	margin: 10px 0;
	border: 1px solid var(--primary-color);
	border-color: var(--primary-color);
	border-radius: 10px;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}
