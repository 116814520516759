.popup-header {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: .4rem;
	margin: 0;
}

.popup-container {
	display: flex;
	color: var(--text-color);
	z-index: 1;
	position: absolute;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	backdrop-filter: blur(8px);
	z-index: 100;
	padding: 1rem;
	transition: all 0.5s;
}

.popup-menu {
	display: flex;
	justify-content: center;
	background-color: var(--secondary-color);
	border-radius: 32px;
	align-items: center;
	padding: 1.6rem;
	border: 1px solid var(--text-color);
}

.popup-body {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: .4rem 1.8rem;
	margin: 0;
}

.popup-body-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: .2rem;
	margin: 0;
}

.popup-input-container {
	display: flex;
	flex-direction: column;
	position: relative;
	align-items: flex-start;
	justify-content: flex-start;
	padding: .8rem .4rem;
}

input {
	color: var(--text-color);
	background-color: var(--secondary-color);
}

.popup-input {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: .6rem .8rem;
	margin: 2px;
	font-size: .8rem;
	transition: all 0.3s;
	border: none;
	color: var(--text-color);
	background-color: var(--secondary-color);
	border-bottom: 1px solid var(--primary-color);
  }
  
  .popup-body-content-item {
	margin-top: 1.5rem;
  }

  .popup-input::after {
	content: "";
	display: block;
	position: absolute;
	bottom: -1px;
	left: 0;
	width: 0%;
	height: 2px;
	color: var(--text-color);
	background-color: var(--primary-color);
	transition: all 0.3s;
  }
  
  .popup-input:hover, .popup-input:focus-within {
	border-bottom: 1px solid transparent;
  }

  .popup-input:hover::after,
  .popup-input:focus-within::after {
	  width: 100%;
	}

/* .input-border {
	border-bottom: 1px solid var(--primary-color);
}

.input-border:focus {
	border-bottom: 2px solid var(--accent-color);
} */
