.input-container {
	display: flex;
	position: relative;
	bottom: 0;
	gap: 1rem;
	padding: 0 .6rem;
	width: 100%;
}

.input-container::before {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	transform: translateY(-100%);
	height: 2rem;
	width: 100%;
	background-color: var(--primary-color);
	background: linear-gradient(
		to bottom,
		transparent 0%,
		var(--primary-color) 100%
	);
}

.input-box {
	margin-bottom: 1.2rem;;
	height: 4rem;
	/* overflow-y: scroll; */
	resize: none;
	border: none;
	border-radius: .75rem;
	font-size: 1.1rem;
	font-weight: 450;
	width: 100%;
	border-radius: 56px;
	padding: .6rem 2rem;
	line-height: 2.5rem;
	background-color: var(--secondary-color);
	color: var(--text-color);
}

.conversation-box {
	display: flex;
	flex: 1;
	justify-content: flex-end;
	flex-direction: column;
	overflow-y: hidden;
	/* height: 100%; */
	width: 100%;
}

.send-button-container {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 1.2rem;
	padding-top: 0;
}

.send-button {
	border-radius: 128px;
	cursor: pointer;
	width: 4.4rem;
	height: 4rem;
	background-color: var(--secondary-color);
	color: var(--text-color);
	border: none;
}

/* @keyframes slide-up {
    from {
      transform: translateY(100%);
	  position: relative;
    }
	to {
		transform: translateY(0);
		position:static;
	}
} */

.messages-container {
	/* display: flex; */
	overflow-y: auto;
	width: 100%;
	padding: 1rem .6rem;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	/* animation: slide-up 0.5s linear both; */
}

.message-outer-box {
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
}


/* .message-outer-box.show {
	transform: translateX(50%);
	transition: all ease 0.5s;
} */

.message-inner-box {
	display: flex;
	flex-direction: column;
	/* height: 100%; */
	max-width: 40rem;
	padding: .6rem 1rem;
	margin: 1rem 0;
	border-radius: 32px;
}

.message-content-box{
	display: flex;
	/* height: 100%; */
	max-width: 40rem;
	font-size: .9rem;
	font-weight: 550;
	color: var(--text-color);
	padding: 0rem .4rem;
}

.message-meta {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	font-size: .6rem;
	width: 100%;
}

.message-sender {
	color: var(--text-color);
	opacity: 0.65;
	padding: 0 .4rem;
}

/* @keyframes slide-left {
    from {
      transform: translateX(100%);
    }
	to {
		transform: translateX(0);
	}
}

@keyframes slide-right {
    from {
      transform: translateX(-100%);
    }
	to {
		transform: translateX(0);
	}
} */

.sender-me {
	background-color: var(--platinum-color);
	border: 2px solid var(--accent-color);
	/* animation: slide-right 0.2s linear; */
}

.sender-other {
	background-color: var(--silver-color);
	border: 2px solid var(--primary-color);
	/* animation: slide-left 0.2s linear; */
}

.right {
	display: flex;
	align-items: flex-end;
}

.left {
	display: flex;
	align-items: flex-start;
}

.bg-primary {
	background-color: var(--primary-color);
}

.bg-secondary {
	background-color: var(--secondary-color);
}

.bg-white {
	background-color: var(--secondary-color);
}

.bg-accent {
	background-color: var(--accent-color);
}

.bg-light-accent {
	background-color: var(--primary-color);
}

.message-content {
	width: 100%;
	word-wrap: break-word;
}
