.profile-container {
	display: flex;
	/* flex-direction: column; */
	justify-content: space-around;
	padding-bottom: 1.2rem;
	align-items: center;
	color: var(--text-color);
}

.round-picture {
	border: 1px solid var(--primary-color);
	background-color: var(--primary-color);
}

.picture-container {
	padding: .8rem;
}

.profile-header {
	display: flex;
	flex-direction: column;
	justify-content: center;
	/* align-items: center; */
	position: relative;
	background-color: var(--secondary-color);
	width: 100%;
	height: 256px;
	border-radius: 32px;
}

.profile-picture-container {
	position: absolute;
	left: 56px;
	/* padding: .8rem; */
	border-radius: 50%;
	width: 128px;
	height: 128px;
	overflow: hidden;
}

.profile-info-container {
	position: absolute;
	left: 192px;
	padding: .2rem .8rem;
}

.profile-username {
	display: flex;
	font-size: 1.2rem;
	font-weight: 500;
}

.banner-top {
	display: flex;
	justify-content: center;
	width: 100%;
	height: 50%;
}

.banner-picture {
	width: 100%;
	height: 100%;
	background: repeating-linear-gradient(
		45deg,
		var(--primary-color) 10px,
		var(--primary-color) 12px,
		transparent 12px,
		transparent 20px
		);
	border-bottom: 1px solid var(--primary-color);
}

.banner-bottom {
	display: flex;
	justify-content: flex-end;
	width: 100%;
	height: 50%;
}

.profile-actions {
	position: absolute;
	display: flex;
	padding: .8rem 1.2rem;
}

.profile-action-container {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: .4rem .8rem;
	cursor: pointer;
	z-index: 10;
	transition: all 0.3s;
}

.profile-action-button {
	display: flex;
	justify-content: center;
	width: 100%;
	align-items: center;
	padding: .6rem 1.6rem;
	cursor: pointer;
	background-color: var(--accent-color);
	border-radius: 56px;
	transition: all 0.3s;
}

.profile-action-button:hover {
	opacity: 0.80;
}
