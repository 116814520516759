.multiple-chats-container {
	display: grid;
	gap: 1rem;
	grid-template-columns: repeat(1, 1fr);
	width: 100%;
}

@media screen and (min-width: 980px) {
    .multiple-chats-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (min-width: 1200px) {
    .multiple-chats-container {
        grid-template-columns: repeat(3, 1fr);
    }
}

.chat-container {
	display: flex;
	position: relative;
	min-width: 324px;
	min-height: 120px;
}

.chat-box {
	display: flex;
	background-color: var(--secondary-color);
	justify-content: space-between;
	align-items: center;
	overflow: hidden;
	padding: .8rem;
	width: 100%;
	border-radius: 28px;
	box-shadow: 4px 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.chat-header {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 100%;
	font-size: 14px;
	padding: 10px 20px;
}

.chat-chatter {
	font-size: 13px;
	font-weight: 600;
	color: var(--text-color);
	background-color: var(--accent-color);
	border: 1px solid var(--accent-color);
	padding: .1rem .4rem;
	margin-bottom: .2rem;
	border-radius: .4rem;
	cursor: pointer;
	opacity: 0.85;
	transition: all 0.3s;
}

.chat-chatter:hover {
	opacity: 0.80;
	border: 1px solid var(--text-color);
}

.chat-chatter::before {
	content: "@";
	font-size: 13px;
}

.chat-body {
	display: flex;
	flex-direction: column;
	font-size: 16px;
	justify-content: center;
	align-items: center;
	color: var(--text-color);
	/* overflow-y: auto; */
}

.chat-footer {
	display: flex;
	/* position: absolute; */
	/* bottom: 0;
	right: 0; */
}

.chat-topic {
	font-size: 16px;
	align-items: center;
	padding: 10px 20px;
}

.chat-text {
	font-size: 16px;
	padding: 10px 20px;
	background-color: var(--secondary-color);
}

.button-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.chat-button {
	background-color: var(--accent-color);
	border: 1px solid var(--secondary-color);
	color: var(--text-color);
	border-radius: 10px;
	padding: 14px 20px;
	/* margin: 8px 8px; */
	cursor: pointer;
	width: 100%;
	transition: all 0.3s;
}

.chat-button:hover {
	background-color: var(--secondary-color);
	border: 1px solid var(--accent-color);
	color: var(--text-color);
}
