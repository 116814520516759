.bot-page-container {
	display: flex;
}

.bot-page {
	display: flex;
	width: 100%;
	flex-wrap: wrap;
}

.bot-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: .4rem 0rem;
}

.bot-outer-box {
	display: flex;
	position: relative;
	overflow: hidden;
	align-items: center;
	min-width: 24rem;
	max-width: 32rem;
	padding: 1rem;
	margin: 1rem;
	color: var(--text-color);
	background-color: var(--secondary-color);
	border: transparent 2px solid;
	border-radius: 32px;
}

.bot-inner-box {
	display: flex;
	padding: 0 .8rem;
	height: 100%;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

.delete-button {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	right: 0;
	cursor: pointer;
	opacity: 0.4;
	transform: translate(-25%, 25%);
	transition: opacity .3s;
}

.delete-button:hover {
	opacity: 0.8;
}

.bot-picture {
	display: flex;
	color: var(--text-color);
	justify-content: center;
	align-items: center;
	width: 164px;
	height: 164px;
	overflow: hidden;
	border-radius: 24px;
	background-color: var(--accent-color);
}

.bot-img {
	color: var(--text-color);
	border-radius: 24px;
	object-fit: cover;
	margin: auto;
	text-align: center;
	max-width: 80%;
}

.bot-name {
	font-size: 1.2rem;
	font-weight: 600;
	padding: .6rem 0;
}

.bot-description {
	font-size: .9rem;
	font-weight: 550;
}

.bot-chat-button {
	position: absolute;
	bottom: 0;
	right: 0;
	transform: translate(-20%, -20%);
	padding: .8rem;
	background-color: var(--primary-color);
	border-radius: 32px;
}

.create-bot-container {
	display: flex;
	min-width: 24rem;
	/* flex: 1 1 auto; */
	height: 196px;
	margin: 1rem;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 1.2rem;
}