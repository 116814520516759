.page-content {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    padding: 0.8rem 0;
    transition: all ease-in 0.3s;
}

.home-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.welcome {
	width: 100%;
	height: 100%;
    font-size: x-large;
    font-weight: 600;
    color: aliceblue;
    max-width: 396px;
    text-align: center;
    text-shadow: 16px 16px 16px rgba(0, 0, 0, 0.1);
}

.bubble-button {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #f5f5f5;
	border: 2px solid transparent;
	padding: .8rem 2rem;
	margin: 1rem 1rem;
	border-radius: 56px;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
	transition: all 0.3s;
	cursor: pointer;
}

.bubble-button:hover {
	border-color: var(--primary-color);
}

.flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.rounded {
    border-radius: 56px;
}

.border {
    border: 1px solid black;
    border-color: #000000;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.blur {
    filter: blur(5px);
}
