.auto-complete {
	display: flex;
	position: absolute;
	overflow: hidden;
	top: 0;
	left: 0;
	z-index: 0;
	padding: .6rem .8rem;
	transition: all 0.3s;
}

.auto-complete-value {
	position: relative;
	display: flex;
	overflow: hidden;
	line-height: 1rem;
	word-spacing: normal;
	border: none;
	font-size: .8rem;
	z-index: 10;
	width: 100%;
	height: 100%;
	cursor: pointer;
	color: var(--accent-color);
	margin-right: 3px;
	opacity: 1;
}

.auto-complete-match {
	position: relative;
	display: flex;
	line-height: 1rem;
	word-spacing: normal;
	border: none;
	font-size: .8rem;
	width: 100%;
	height: 100%;
	cursor: pointer;
	opacity: 0.4;
}
