:root {
	--primary-color: #E6E6E9;
	--secondary-color: #F4F4F6;/* hsla(300, 36%, 42%, 0.015); */
	--text-color: #424242;
	--accent-color: #151317;

	--dark-accent-color: #ffffff;
	--red-color: #e7acac;

	--platinum-color: #ffffff;
	--silver-color: #ffffff;
}

* {
	padding: 0;
	margin: 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	outline: none;
}

input {
	border: none;
}

body {
	/* overflow-y: hidden; */
	line-height: 1.5;
	background-color: var(--primary-color);
}

/* body, #root, .page-container {
	height: 100%;
} */

.page-container {
	display: flex;
	gap: 1rem;
	justify-content: center;
	align-items: center;
	padding: 1rem;
	width: 100%;
	height: 100vh;
}

.no-select {
	user-select: none;
	-moz-user-select: none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	-o-user-select: none;
}

textarea {
	resize: none;
}

.page-font, input, textarea, button {
  font-family: "Quicksand", sans-serif;
  font-optical-sizing: auto;
  word-spacing: normal;
  font-style: normal;
  font-weight: 500;
  font-size: .8rem;
}

.page-content-container {
	display: flex;
	height: 100%;
	/* position: relative; */
	flex-direction: column;
	/* padding: 0 0 0 .8rem; */
	width: 100%;
}


.shadow {
	box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
}