.sidebar-container {
	flex: 1;
	overflow-y: auto;
	height: 100%;
	min-width: 228px;
}

.users-search-bar-container {
	display: flex;
	width: 100%;
	padding: 0 .8rem;
	margin-bottom: .8rem;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.users-search-bar {
	display: flex;
	width: 100%;
	line-height: 56px;
	padding: 0 2.4rem;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border-radius: 56px;
	background-color: var(--secondary-color);
	color: var(--text-color);
}

.sidebar-items {
	position: relative;
	list-style: none;
	width: 100%;
	color: var(--text-color);
	padding: 0 1.2rem;
	padding-bottom: .8rem;
}

.sidebar-element-container {
	display: flex;
	width: 100%;
	cursor: pointer;
	margin-bottom: .8rem;
	/* padding: .8rem 0; */
	align-items: center;
}

.sidebar-element {
	display: flex;
	width: 100%;
	font-size: 14px;
	padding: 1.2rem .8rem;
	justify-content: center;
	align-items: center;
	background-color: var(--secondary-color);
	border-radius: 56px;
}

.accent-on-hover {
	transition: all .3s;
}

.accent-on-hover:hover {
	opacity: 0.80;
}